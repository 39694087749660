<template>
  <div>
    <h1 class="heading">Notification statistics</h1>
    <ObjectFilter
        :object_id="$store.state.statistics.object_id"
        :objects="objects.list"
        @changeObject="changeObject"
        @changeDates="changeDates"
        @reloadData="reloadData"
    />
    <v-tabs>
      <v-tab>
        Clients
      </v-tab>
      <v-tab>
        Messages
      </v-tab>
      <v-tab-item>
        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <div class="statistics__chart">
              <Chart
                  :data="chart_data_clients"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info" v-if="!isMobile">
            <h3 class="statictics__caption">
              {{ $t('statistics["Количество посещений"]') }}
            </h3>
            <p class="statistics__period">
              <span>{{ all_visitors_clients }}</span>
              {{ $t('statistics["за текущий период"]') }}
            </p>
            <p class="statistics__total">
            <span
            >The graph shows the number of clients, who allow to notificate  by your guests through
              the mobile app: {{ hotelName }}</span
            >
            </p>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <div class="statistics__chart">
              <Chart
                  :data="chart_data_messages"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info" v-if="!isMobile">
            <h3 class="statictics__caption">
              Messages for the period
            </h3>
            <p class="statistics__period">
              <span>{{ all_visitors_messengers }}</span>
              {{ $t('statistics["за текущий период"]') }}
            </p>
            <p class="statistics__total">
            <span
            >The graph shows the number of messages send to your guests through
              the mobile app: {{ hotelName }}</span
            >
            </p>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
  import ObjectFilter from "../../ObjectFilter";
  import store from "@/store"
  import NotificationService from "../../../services/notification.service";
  import titleMixin from "../../../mixins/titleMixin";
  import {mapState} from "vuex";
  export default {
    name: "NotificationStatistics",
    mixins:[titleMixin],
    title(){
      return "Notification Statistics"
    },
    computed:{
      ...mapState(['objects']),
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    beforeRouteEnter(to, from, next) {
      if (!store.state.objects.list.length) {
        store
          .dispatch("objects/getList")
          .then((data) => {
            next();
          })
          .catch(() => {
            next();
          });
      } else {
        next();
      }
    },
    components:{
      ObjectFilter,
      Chart: () => import("@/components/Chart.vue"),
    },
    data(){
      return{
        isMobile:false,
        hotelName:"",
        clients_statistics:[],
        messages_statistics:[],
        chart_data_clients:[],
        chart_data_messages:[],
        all_visitors_clients: 0,
        all_visitors_messengers: 0,
        dateArr:[]
      }
    },
    mounted() {
      this.hotelName = this.objects.list.find(x => x.id === this.$store.state.statistics.object_id).name;
    },
    methods:{
      async changeObject(id) {
        this.$store.state.statistics.object_id = id;
        if (
          !this.$store.state.statistics.date_start &&
          !this.$store.state.statistics.date_end
        ) {
          let end = this.$moment().format("YYYY-MM-DD");
          let start = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
          this.$store.state.statistics.date_start =
            this.$moment(start).format("DD.MM.YYYY");
          this.$store.state.statistics.date_end =
            this.$moment(end).format("DD.MM.YYYY");
        }
        this.hotelName = this.objects.list.find((x) => x.id === id).name;
        await this.notificationStatistics();
      },
      async changeDates(dates) {
        this.labels = [];
        if (dates.date_start && dates.date_end) {
          this.$store.state.statistics.date_start = this.$moment
            .unix(dates.date_start)
            .format("DD.MM.YYYY");
          this.$store.state.statistics.date_end = this.$moment
            .unix(dates.date_end)
            .format("DD.MM.YYYY");
        } else {
          this.$store.state.statistics.date_start = dates.date_start;
          this.$store.state.statistics.date_end = dates.date_end;
        }
        await this.notificationStatistics();
      },
      reloadData(){
        this.notificationStatistics();
      },
      formatDateForStatistics(date) {
        const dateArr = date.split(".");
        return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
      },
      formatDateForChart(date) {
        if (date.includes("-")) {
          const dateArr = date.split("-");
          return `${dateArr[2]}.${dateArr[1]}`;
        } else {
          return date;
        }
      },
      async notificationStatistics(){
        const payload = {
          hotel_id: this.$store.state.statistics.object_id,
          date_start: this.formatDateForStatistics(
            this.$store.state.statistics.date_start
          ),
          date_end: this.formatDateForStatistics(
            this.$store.state.statistics.date_end
          ),
        };
        this.clients_statistics = await NotificationService.getClientsStatistics(payload);
        this.messages_statistics = await NotificationService.getMessagesStatistics(payload);

        this.chart_data_clients = [];
        this.chart_data_messages = [];
        this.dateArr = [];
        this.all_visitors_messengers = 0;
        this.all_visitors_clients = 0;

        for (let i in this.clients_statistics) {
          this.dateArr.push(this.formatDateForChart(i));
          this.chart_data_clients.push(this.clients_statistics[i]);
          this.all_visitors_clients = this.all_visitors_clients + this.clients_statistics[i];
        }
        for (let i in this.messages_statistics) {
          this.chart_data_messages.push(this.messages_statistics[i]);
          this.all_visitors_messengers = this.all_visitors_messengers + this.messages_statistics[i];
        }

      }
    }
  }
</script>

<style scoped lang="scss">

  .statistics {
    @include box;
    display: flex;
    // align-items: flex-end;
    margin-bottom: 30px;

    canvas {
      width: 100% !important;
      height: 415px !important;
    }
  }

  .statistics__statistics {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(66.66% + 20px);
  }

  .statistics__type {
    position: absolute;
    width: 320px;
    top: 30px;
    left: 60px;
  }

  .statistics__chart {
    margin-top: auto;
  }

  .statistics__info {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 20px);
  }

  .statictics__caption {
    font-size: 16px;
    font-weight: 600;
    padding: 40px 40px 0;
  }

  .statistics__period {
    margin-bottom: auto;
    padding: 40px;
    font-weight: 500;
    font-size: 14px;
    color: #7f8385;
    span {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 80px;
      line-height: 1.21;
      color: #007db5;
      white-space: nowrap;
    }
  }

  .statistics__total {
    margin-top: auto;
    padding: 40px;
    border-radius: 0 0 5px 0;
    background: rgb(190, 193, 194);
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    span {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.22;
    }
  }

  .statistics__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
</style>
